import { BaseService, Service } from "/@/cool";
@Service("customerP3dLog")
class CustomerP3dLog extends BaseService {
	constructor() {
		super();

		let prefix = "";
		if (this.namespace) {
			prefix = this.namespace.replace(/\//g, ":") + ":";
		}

		this.permission["orderPreview"] = prefix + "orderPreview";
		this.permission["downloadPreviewScenePhoto"] = prefix + "downloadPreviewScenePhoto";
		// this.permission["downloadModel"] = prefix + "downloadModel";
		this.permission["uploadModel"] = prefix + "uploadModel";
		this.permission["previewModel"] = prefix + "previewModel";
		this.permission["previewScene"] = prefix + "previewScene";
		this.permission["previewAllScene"] = prefix + "previewAllScene";
		this.permission["previewModelScene"] = prefix + "previewModelScene";
		this.permission["customerService"] = prefix + "customerService";
		this.permission["takephotoAllImages"] = prefix + "takephotoAllImages";
		this.permission["excel"] = prefix + "excel"; // 取消
		this.permission["info"] = prefix + "info";
	}
 

	modelTakephotoAllImages(params: any) {
		return this.request({
			url: "/modelTakephotoAllImages",
			method: "POST",
			data: { ...params }
		});
	}

	takephotoAllImages(params: any) {
		return this.request({
			url: "/takephotoAllImages",
			method: "POST",
			data: { ...params }
		});
	}
 
	// 门店小票
	shopTicketInfo(params: any) {
		return this.request({
			url: "/shopTicketInfo",
			method: "POST",
			data: { ...params }
		});
	}
	//
	getStudioType(params: any) {
		return this.request({
			url: "/getStudioType",
			method: "POST",
			data: { ...params }
		});
	}
}
export default CustomerP3dLog;
