import { BaseService, Service } from "/@/cool";
@Service("orders")
class Orders extends BaseService {
	constructor() {
		super();

		let prefix = "";
		if (this.namespace) {
			prefix = this.namespace.replace(/\//g, ":") + ":";
		}
		this.permission["page"] = prefix + "page";
		this.permission["expressedPage"] = prefix + "expressedPage";
		this.permission["info"] = prefix + "info";
		this.permission["details"] = prefix + "details";
		this.permission["modifyAddress"] = prefix + "modifyAddress";
		this.permission["ticket"] = prefix + "ticket";
		this.permission["expeditedPage"] = prefix + "expeditedPage";
	}

	expressedPage(data: any) {
		return this.request({
			url: "/expressedPage",
			method: "post",
			data
		});
	}

	modifyAddress(data: any) {
		return this.request({
			url: "/modifyAddress",
			method: "post",
			data
		});
	}

	details(data: any) {
		return this.request({
			url: "/details",
			method: "post",
			data
		});
	}

	ticket(data: any) {
		return this.request({
			url: "/ticket",
			method: "post",
			data
		});
	}
	expeditedPage(data: any) {
		return this.request({
			url: "/expeditedPage",
			method: "post",
			data
		});
	}
}
export default Orders;
